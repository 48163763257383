// Environment
export const DEV_ENV = false;
export const LOG = false;

export const PROD_ROOT_URL = 'https://api.ymcaallahabad.in';
export const DEV_ROOT_URL = 'http://sagokomm-ymca-backend';
export const ROOT_URL = DEV_ENV ? DEV_ROOT_URL : PROD_ROOT_URL;
export const API_URL = `${ROOT_URL}/api/v1/`;
export const TOKEN_STORAGE_KEY = 'authToken';
export const USER_STORAGE_KEY = 'user_storage';
export const EXAM_KEY = 'exam_key_';
export const TEST_NOT_STARTED = 'Test not yet started';
export const TEST_EXPIRED = 'Test expired';
export const TEST_ALREADY_SUBMITTED = 'Test already submitted';

export const NAV_SCREENS = {
  LOGIN: 'LOGIN',
  DASHBOARD: 'DASHBOARD',
};

export const EXAM_SUBMISSION_STATUSES = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
};
