import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { refreshToken, exchangeToken } from 'actions/UserActions';
import { getParams } from 'utilities/Helpers';
import { Base64 } from 'js-base64';
import { TOKEN_STORAGE_KEY } from 'utilities/Constants';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('views/pages/login/Login'));
const Register = React.lazy(() => import('views/pages/register/Register'));
const Page404 = React.lazy(() => import('views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('views/pages/page500/Page500'));

class Navigator extends Component {
  componentDidMount() {
    const params = getParams(window.location.href);
    if (params.uid) {
      localStorage.setItem(TOKEN_STORAGE_KEY, Base64.decode(params.uid));
      this.props.exchangeToken();
    } else {
      this.props.refreshToken();
    }
  }

  render() {
    if (!this.props.auth.loggedIn) {
      return (
        <React.Suspense fallback={loading}>
          <Login />
        </React.Suspense>
      );
    }

    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

function mapState(state) {
  const { auth } = state;
  return { auth };
}

const mapDispatchToProps = (dispatch) => {
  return {
    refreshToken: () => refreshToken(dispatch),
    exchangeToken: () => exchangeToken(dispatch),
  };
};

export default connect(mapState, mapDispatchToProps)(Navigator);
