import { TOKEN_STORAGE_KEY, LOG } from 'utilities/Constants';
import {
  LOGIN,
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_UNSUCCESSFUL,
  LOGIN_SUCCESSFUL,
  LOGIN_USER,
  HYDRATE_USER_DATA,
  LOGOUT_USER,
  REFRESHING_TOKEN,
  REFRESH_SUCCESSFUL,
  // INITIATE_BACKGROUND_APP_LOADING,
  // BACKGROUND_APP_LOAD_COMPLETE,
  // PASSWORD_RESET_SUCCESSFULLY,
  // OBSOLETE,
} from 'actions/actionTypes';
import { LoginApi, LogoutApi, RefreshTokenApi, ExchangeTokenApi } from 'api/LoginApi';
import { getUserDetailsApi } from 'api/UserApi';
// import { GetVersion } from 'api/VersionApi';
// import { toTitleCase, checkVersionObsolete } from 'utilities/Helpers';

export const Login = () => {
  return {
    type: LOGIN,
  };
};

export const emailChanged = (text) => {
  return {
    type: EMAIL_CHANGED,
    payload: text,
  };
};

export const passwordChanged = (text) => {
  return {
    type: PASSWORD_CHANGED,
    payload: text,
  };
};

export const loginUser = (dispatch, data) => {
  const { email, password } = data;
  dispatch({ type: LOGIN_USER });
  LoginApi(email, password)
    .then((user) => {
      localStorage.setItem(TOKEN_STORAGE_KEY, user.token);
      getUserDetailsApi()
        .then((userdata) => {
          dispatch({ type: HYDRATE_USER_DATA, payload: userdata });
          dispatch({ type: LOGIN_SUCCESSFUL, payload: userdata });
        })
        .catch();
    })
    .catch((e) => {
      if (LOG) {
        console.log(e);
      }
      dispatch({ type: LOGIN_UNSUCCESSFUL });
    });
};

export const logout = (dispatch) => {
  dispatch({ type: LOGOUT_USER });
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  LogoutApi()
    .then(() => {})
    .catch(() => {});
};

export const refreshToken = (dispatch) => {
  dispatch({ type: REFRESHING_TOKEN });
  RefreshTokenApi()
    .then((token) => {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
      getUserDetailsApi()
        .then((user) => {
          dispatch({ type: HYDRATE_USER_DATA, payload: user });
          dispatch({ type: REFRESH_SUCCESSFUL });
        })
        .catch();
    })
    .catch(() => {
      dispatch({ type: LOGOUT_USER });
    });
};

export const exchangeToken = (dispatch) => {
  dispatch({ type: REFRESHING_TOKEN });
  ExchangeTokenApi()
    .then((token) => {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
      getUserDetailsApi()
        .then((user) => {
          dispatch({ type: HYDRATE_USER_DATA, payload: user });
          dispatch({ type: REFRESH_SUCCESSFUL });
        })
        .catch();
    })
    .catch(() => {
      dispatch({ type: LOGOUT_USER });
    });
};
//
// export const passwordResetSuccessfully = () => {
//   return { type: PASSWORD_RESET_SUCCESSFULLY };
// };
