import {
  EMAIL_CHANGED,
  PASSWORD_CHANGED,
  LOGIN_SUCCESSFUL,
  LOGIN_UNSUCCESSFUL,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESHING_TOKEN,
  REFRESH_SUCCESSFUL,
  BACKGROUND_APP_LOAD_COMPLETE,
  OBSOLETE,
  HYDRATE_USER_DATA,
} from 'actions/actionTypes';

const INITIAL_STATE = {
  email: '',
  loggedIn: false,
  error: false,
  loading: false,
  refreshing: false,
  splashScreenVisible: true,
  versionObsolete: false,
  userData: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EMAIL_CHANGED:
      return { ...state, email: action.payload };
    case PASSWORD_CHANGED:
      return { ...state, password: action.payload };
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        refreshToken: false,
        email: action.payload.email,
      };
    case HYDRATE_USER_DATA:
      return { ...state, userData: action.payload };
    case LOGIN_UNSUCCESSFUL:
      return { ...state, error: true, loading: false };
    case LOGOUT_USER:
      return INITIAL_STATE;
    case BACKGROUND_APP_LOAD_COMPLETE:
      return { ...state, splashScreenVisible: false };
    case OBSOLETE:
      return { ...state, versionObsolete: true };
    case REFRESHING_TOKEN:
      return { ...state, refreshing: true };
    case REFRESH_SUCCESSFUL:
      return {
        ...state,
        refreshing: false,
        loading: false,
        loggedIn: true,
      };
    default:
      return state;
  }
};
