import moment from 'moment';

export const examStartingTime = (exam) => {
  if (exam.staggered_entry) {
    return `${moment(exam.start_time).format('h:mm a')} to ${moment(exam.start_time).add(exam.staggered_entry_duration, 's').format('h:mm a')}`;
  }
  return moment(exam.start_time).format('h:mm a');
};

export const secondsFormatter = (value) => {
  const hours = parseInt(value / 3600, 10) > 0 ? parseInt(value / 3600, 10).toString().concat(`hr${parseInt(value / 3600, 10) > 1 ? 's' : ''}`) : '';
  const hoursRemainder = value % 3600;
  const mins = parseInt(hoursRemainder / 60, 10) > 0 ? '00'.concat(parseInt(hoursRemainder / 60, 10)).slice(-2).concat(`min${parseInt(hoursRemainder / 60, 10) > 1 ? 's' : ''}`) : '';
  let formattedTime = parseInt(value / 3600, 10) > 0 ? hours.concat(' ').concat(mins) : mins;
  formattedTime = formattedTime.trim();
  return formattedTime;
};

export const countdownFormatter = (value) => {
  const hours = parseInt(value / 3600, 10) > 0 ? parseInt(value / 3600, 10).toString().concat(':') : '';
  const hoursRemainder = value % 3600;
  const mins = ('00'.concat(parseInt(hoursRemainder / 60, 10))).slice(-2);
  const secs = ('00'.concat(hoursRemainder % 60)).slice(-2);
  let formattedTime = hours.concat(mins).concat(':').concat(secs);
  formattedTime = formattedTime.trim();
  return formattedTime;
};

export const getParams = (url) => {
  const path = url.split('/').pop();
  const queryString = path.split('?').pop();
  const params = queryString.split('&');
  const paramsObj = {};
  params.forEach((param) => {
    const [key, value] = param.split('=');
    if (value) paramsObj[key] = value;
  });
  return paramsObj;
};
