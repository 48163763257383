import axios from 'axios';
// import localStorage from '@react-native-community/async-storage';
import {
  API_URL,
  TOKEN_STORAGE_KEY,
  LOG,
} from './Constants';

axios.defaults.baseURL = API_URL;

const _getURL = (url) => {
  return url;
};

const _createHeaders = () => {
  const token = localStorage.getItem(TOKEN_STORAGE_KEY);
  return {
    Authorization: `Bearer ${token}`,
  };
};

const _handleError = (error, reject) => {
  if (error.response && error.response.data) {
    if (LOG) { console.log(error.response.data); }
    reject(error.response.data);
    return;
  }
  if (LOG) { console.log(error); }
  reject(error);
};

const _checkResponse = (data) => {
  if (!data || !data.success || data.code !== 200) {
    throw new Error('Malformed Response');
  }
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

export const GET = (url) => {
  if (LOG) { console.log('GET URL:', url); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.get(_getURL(url), { headers })
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) { console.log('Success:', data.data); }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};

export const POST = (url, body) => {
  if (LOG) { console.log('POST URL:', url, ',body: ', body); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.post(_getURL(url), body, { headers })
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) { console.log('Success:', data.data); }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};

export const PUT = (url, body) => {
  if (LOG) { console.log('PUT URL:', url, ',body: ', body); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.put(_getURL(url), body, { headers })
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) { console.log('Success:', data.data); }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};

export const DELETE = (url) => {
  if (LOG) { console.log('DELETE URL:', url); }
  return new Promise((resolve, reject) => {
    const headers = _createHeaders();
    axios.delete(_getURL(url), { headers })
      .then(({ data }) => {
        _checkResponse(data);
        if (LOG) { console.log('Success:', data.data); }
        resolve(data.data);
      })
      .catch((error) => {
        _handleError(error, reject);
      });
  });
};
