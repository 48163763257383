/* eslint max-len: 0 */
// Common Actions
export const LOGIN = 'LOGIN';
export const EMAIL_CHANGED = 'EMAIL_CHANGED';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const LOGIN_UNSUCCESSFUL = 'LOGIN_UNSUCCESSFUL';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const HYDRATE_USER_DATA = 'HYDRATE_USER_DATA';
export const ATTENDANCE_LIST_FETCH = 'ATTENDANCE_LIST_FETCH';
export const ATTENDANCE_LIST_NOT_FETCH = 'ATTENDANCE_LIST_NOT_FETCH';
export const ATTENDANCE_HYDRATE_DATA = 'ATTENDANCE_HYDRATE_DATA';
export const ATTENDANCE_HYDRATE_DATA_DETAIL = 'ATTENDANCE_HYDRATE_DATA_DETAIL';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const REFRESH_SUCCESSFUL = 'REFRESH_SUCCESSFUL';
export const BACKGROUND_APP_LOAD_COMPLETE = 'BACKGROUND_APP_LOAD_COMPLETE';
export const PASSWORD_RESET_SUCCESSFULLY = 'PASSWORD_RESET_SUCCESSFULLY';
export const OBSOLETE = 'OBSOLETE';
