import { POST } from 'utilities/AxiosHelpers';

export const LoginApi = (email, password) => {
  return new Promise((resolve, reject) => {
    POST('login', {
      email,
      password,
    })
      .then(resolve)
      .catch(reject);
  });
};

export const LogoutApi = () => {
  return new Promise((resolve, reject) => {
    POST('logout')
      .then(resolve)
      .catch(reject);
  });
};

export const RefreshTokenApi = () => {
  return POST('refresh');
};

export const ExchangeTokenApi = () => {
  return POST('exchange_token');
};
